@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.weather {
  &__outer {
    @include container;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    margin: 1.5rem auto;
    @include tablet {
      justify-content: center;
      width: 100%;
      margin: 0;
      gap: 3rem;
      padding: 1.3rem;
    }
    @include desktop {
      flex-direction: column;
      width: 15%;
      padding: 0;
      margin: 1rem 0;
    }
  }
  &__container {
    color: #2f3640;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    @include tablet {
      justify-content: space-between;
      align-items: center;
    }
    @include desktop {
      flex-direction: column;
      padding: 0;
    }
  }

  &__description {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 5px;
    overflow-wrap: break-word;
    &--first {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }

  &__temp {
    font-size: 1.5rem;
    font-weight: 600;
    justify-content: end;
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    @include tablet {
      width: 6.25rem;
      height: 6.25rem;
    }
  }
}
