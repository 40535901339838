@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
.status {
  &-container {
    @include container;
    margin: 3rem 0;
  }
  &__title {
    display: flex;
    justify-content: center;
  }

  &__update-container {
    text-align: center;
    padding: 0 1rem;
  }
  &__update {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    font-size: 0.9rem;
    line-height: 1rem;
    width: 100%;

    @include tablet {
      font-size: 1.2rem;
    }

    @include desktop {
      font-size: 1.3rem;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__circular {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 13%;
  }

  &__logo {
    width: 2rem;
  }
  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  &__button {
    display: flex;
    align-items: center;
    background-color: black;
    color: $primary-text-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 0.4rem;
    &--rail {
      background-color: black;
    }

    &--tube {
      background-color: map-get($colors, victoria);
    }
    p {
      margin: 0.5rem 0;
    }

    @include tablet {
      padding: 0.2rem 0.7rem;
      font-size: 1.1rem;
    }
    @include desktop {
      padding: 0.3rem 0.8rem;
      font-size: 1.25rem;
    }
  }
  &__logo {
    margin-right: 0.5rem;
  }
}
