@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.hero {
  &__picture {
    position: relative;
    height: 18rem;
    @include tablet {
      height: 25rem;
    }

    @include desktop {
      height: 38rem;
    }

    &:hover {
      cursor: pointer;
    }
    &--base {
      &:hover {
        cursor: default;
      }
    }
  }

  &__badge {
    &--base {
      display: none;
    }
    position: absolute;
    right: 0rem;
    top: 3rem;
    width: 12rem;
    height: 3.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    @include tablet {
      top: 6rem;
      width: 13rem;
      height: 4rem;
      padding: 1rem;
    }
    @include desktop {
      top: 10rem;
      width: 14rem;
      height: 5rem;
      padding: 1rem;
    }
  }

  &__text {
    margin: 0 0 0 0.3rem;
    color: $primary-text-color;
    &--circle {
      color: $secondary-text-color;
    }
    font-size: 0.9rem;
    @include tablet {
      font-size: 1rem;
    }
  }
}
