@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.list {
  &__container {
    padding: 2rem;
    width: 100vw;
    @include desktop {
      width: 30vw;
    }
  }
  &__form {
    width: 12rem;
    height: 2.2rem;
    margin: 0.5rem 0;
    margin-bottom: 2.5rem;
  }

  &__card {
    height: 75vh;
    overflow: auto;
  }

  &__filter {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__circular {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 13%;
  }
}
