@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  &__title {
    font-weight: 500;
    font-size: 2rem;
    margin: 1.8rem auto 2rem;
    @include tablet {
      font-size: 3rem;
      margin: 2rem 0 3rem;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    gap: 0.4rem;
    &--name {
      flex-direction: row;
    }
    &--confirm {
      margin-top: 0.6rem;
    }
  }

  &__name {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 0.4rem;
  }

  &__input {
    border-radius: 3px;
    padding: 0.5rem;
    border-radius: 4px;
    border: 2px solid #e0e0e0;
    letter-spacing: 0.01rem;
    padding: 0.3rem 0.3rem 0.3rem 0.5rem;
    height: 2.8rem;
    &--error {
      border: 2px solid red;
    }
    &::placeholder {
      font-size: 0.95rem;
    }
  }
  &__valid {
    color: green;
    &:before {
      content: "✔";
    }
  }
  &__invalid {
    color: red;
    &:before {
      position: relative;
      content: "✖";
    }
  }

  &__valid,
  &__invalid {
    line-height: 0.7rem;
    font-size: 0.8rem;
    @include tablet {
      font-size: 0.9rem;
    }
  }

  &__button {
    margin-top: 0.5rem;
    border-radius: 8px;
    font-size: 1rem;
    padding: 1rem;
    color: white;
    background-color: map-get($map: $colors, $key: base);
    border: none;
    font-size: 1.2rem;
    transition: 0.25s;
    cursor: pointer;
    &:hover {
      background-color: black;
    }
    @include tablet {
      padding: 1.2rem;
    }
  }

  &__error {
    color: red;
    margin: 0;
    font-size: 0.85rem;
  }

  &__success {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    color: darkgreen;
  }

  &__modal {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    color: darkgreen;
  }

  &__message {
    top: 50%;
  }

  &__login {
    margin: 1rem auto;
    font-size: 0.9rem;
    @include tablet {
      font-size: 1rem;
    }
  }

  &__link {
    color: blue;
  }

  &__icon {
    float: right;
    position: relative;
    cursor: pointer;
    filter: opacity(0.5);
    @include tablet {
    }
  }

  &__checkbox {
    color: #616161;
    font-size: 0.75rem;
    @include tablet {
      font-size: 0.85rem;
    }
  }

  &__label {
    font-size: 0.9rem;
    @include tablet {
      font-size: 1rem;
    }
  }
}
