@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  height: 100vh;
  overflow: visible;
  box-sizing: content-box;
  transform: scaleX(-1);
}
.picture {
  transform: scaleX(-1);
  background-repeat: no-repeat;
  width: auto;
  height: 100vh;
  @include tablet {
    background-image: url("../../assets/images/underground-bg.jpg");
    display: flex;
    margin: 0 auto;
    width: 100vw;
    top: 0px;
    left: -5rem;
    max-width: none;
    overflow: visible;
    background-position: 62% 100%;
    z-index: -1;
    box-sizing: content-box;
  }
}
.login {
  width: 100vw;
  height: 100vh;
  margin: 1rem;
  @include tablet {
    z-index: 2;
    background-color: white;
    padding: 3rem;
    width: 50vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3%;
  }
  @include desktop {
    padding: 4rem;
    width: 40vw;
    height: 68vh;
  }
}
