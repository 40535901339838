@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.options {
  @include container;

  @include desktop {
    padding: 0 1rem;
    width: 40rem;
  }
  &__title {
    width: 10rem;
    padding: 0.4rem;
    padding-left: 0.5rem;
    background-color: $secondary-text-color;
    color: $primary-text-color;
    align-items: center;
    font-weight: 400;
    border-radius: 3px;
    margin: 1.5rem 0 0.8rem 0;
  }
  &__route {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    border-bottom: 1px solid #e1e4e5;
    flex-wrap: wrap;
    justify-content: space-between;
    &--last {
      border: 0;
    }
    &:hover {
      background-color: #e1e4e8;
      cursor: pointer;
    }
  }

  &__circular {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &__loading {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__methods {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 86%;
  }

  &__chevron {
    width: 0.5rem;
    height: 0.5rem;
    margin: auto 0.5rem;
  }
  &__icon {
    width: 2.1rem;
    height: 1.6rem;
    margin: auto 0;
  }
  &__details {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    margin: 0.6rem 0;
  }
  &__tube {
    display: flex;
    align-items: center;
    margin: auto 0;
    margin-left: 0.25rem;
    padding: 0.2rem 0.5rem;
    padding-top: 0.3rem;
    border-radius: 4px;
    color: $primary-text-color;
  }
  &__bus {
    display: flex;
    align-items: center;
    margin: auto 0;
    padding: 0.35rem 0.5rem 0.15rem 0.5rem;
    border-radius: 4px;
    background-color: #dc241f;
    color: $primary-text-color;
  }

  &__time {
    font-size: 1rem;
    width: 14%;
    & > span {
      font-size: 1.5rem;
    }
  }

  &__walking {
    margin: auto 0;
    color: #000;
    text-transform: lowercase;
    font-size: 1rem;
    & > span {
      margin-left: 0.1rem;
    }
  }

  &__button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    @include desktop {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }

  &__bookmark {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
}
