@use "variables" as *;

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin container {
  padding: 0 0.5rem;

  @include tablet {
    padding: 0 2rem;
  }

  @include desktop {
    padding: 0 8.5rem;
    margin: 0 auto;
    max-width: 1280px;
  }
}
