@font-face {
  font-family: "Johnston";
  src: url("../../assets/fonts/P22-Johnston-Underground-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Johnston";
  src: url("../../assets/fonts/P22-Johnston-Underground-Bold.ttf");
  font-weight: 600;
}
