@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.logos {
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  overflow: hidden;
  padding: 1.3rem 0;
  background: $primary-text-color;
  white-space: nowrap;
  position: relative;

  @include tablet {
    padding: 2rem 0;
  }
  @include desktop {
    padding: 4rem 0;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    width: 12rem;
    height: 100%;
    content: "";
    z-index: 2;
  }

  &:hover &__slider {
    animation-play-state: paused;
  }
  &:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  &:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }

  &__slider {
    display: inline-block;
    white-space: nowrap;
    animation: 30s slide infinite linear;
  }
  &__slider img {
    height: 3.5rem;
    margin: 0 2rem;
    @include tablet {
      height: 5rem;
      margin: 0 2.5rem;
    }
    @include desktop {
      height: 6rem;
      margin: 0 3rem;
    }
  }
}
