$colors: (
  base: #2d3039,
  bakerloo: #b26300,
  central: #dc241f,
  circle: #ffc80a,
  district: #007d32,
  hammersmith-city: #f589a6,
  hammersmith---city: #f589a6,
  jubilee: #838d93,
  metropolitan: #9b0058,
  northern: #000000,
  piccadilly: #0019a8,
  victoria: #039be5,
  waterloo-city: #76d0bd,
  waterloo--city: #76d0bd,
  waterloo---city: #76d0bd,
  bus: #dc241f,
  london-overground: #fa7b05,
  overground: #fa7b05,
  elizabeth: #60399e,
  elizabeth-line: #60399e,
  national-rail: #000000,
  cable-car: #dc241f,
  coach: #ffa600,
  dlr: #00afad,
);

$nr: (
  avanti-west-coast: #004354,
  c2c: #b7007c,
  chiltern-railways: #00bfff,
  cross-country: #660f21,
  crosscountry: #660f21,
  east-midlands-railway: #713563,
  first-hull-trains: #de005c,
  hull-trains: #de005c,
  first-transpennine-express: #010385,
  gatwick-express: #eb1e2d,
  grand-central: #1d1d1b,
  greater-anglia: #d70428,
  great-northern: #0099ff,
  great-western-railway: #0a493e,
  heathrow-express: #532e63,
  island-line: #1e90ff,
  london-north-eastern-railway: #00bf6f,
  lumo: #2b6ef5,
  merseyrail: #fff200,
  northern-rail: #262262,
  scotrail: #1e467d,
  southeastern: #389cff,
  southern: #8cc63e,
  south-western-railway: #24398c,
  thameslink: #ff5aa4,
  transpennine-express: #09a4ec,
  transport-for-wales: #ff0000,
  west-midlands-trains: #ff8300,
);

:root {
  --central: #dc241f;
  --bakerloo: #b26300;
  --central: #dc241f;
  --circle: #ffc80a;
  --district: #007d32;
  --hammersmith-city: #f589a6;
  --hammersmith---city: #f589a6;
  --jubilee: #838d93;
  --metropolitan: #9b0058;
  --northern: #000000;
  --piccadilly: #0019a8;
  --victoria: #039be5;
  --waterloo-city: #76d0bd;
  --waterloo--city: #76d0bd;
  --waterloo---city: #76d0bd;
  --bus: #dc241f;
  --london-overground: #fa7b05;
  --overground: #fa7b05;
  --elizabeth: #60399e;
  --elizabeth-line: #60399e;
  --national-rail: #000000;
  --cable-car: #dc241f;
  --coach: #ffa600;
  --dlr: #00afad;
  --walking: #0019a8;
}

$header-color: #61686b;
$primary-text-color: #ffffff;
$secondary-text-color: #0019a8;
$sub-text: #677680;

$warning: #f5d800;

$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

@each $line, $color in $colors {
  .main__name--#{$line} {
    background-color: $color;
  }
  .header__container--#{$line} {
    background-color: $color;
  }
  .sub-hero__banner--#{$line} {
    background-color: $color;
  }
  .hero__badge--#{$line} {
    background-color: $color;
  }

  .line--#{$line} {
    background-color: $color;
    stroke: $color;
  }
  .options__tube--#{$line} {
    background-color: $color;
  }

  .route__color--#{$line} {
    background-color: $color;
  }

  .route__line--#{$line} {
    border-left: solid $color 5px;
  }

  .table__name--#{$line} {
    background-color: $color;
  }
  @if $line ==
    "circle" or
    $line ==
    "hammersmith-city" or
    $line ==
    "waterloo-city" or
    $line ==
    "waterloo---city" or
    $line ==
    "hammersmith---city"
  {
    .options__tube--#{$line} {
      color: $secondary-text-color;
    }
    .route__color--#{$line} {
      color: $secondary-text-color;
    }
  }
}

@each $line, $color in $nr {
  .table__name--#{$line} {
    background-color: $color;
    @if $line ==
      "avanti-west-coast" or
      $line ==
      "cross-country" or
      $line ==
      "crosscountry" or
      $line ==
      "east-midlands-railway" or
      $line ==
      "first-transpennine-express" or
      $line ==
      "grand-central" or
      $line ==
      "great-western-railway" or
      $line ==
      "heathrow-express" or
      $line ==
      "northern-rail" or
      $line ==
      "scotrail" or
      $line ==
      "south-western-railway"
    {
      color: $primary-text-color;
    }
  }
}
