@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.route {
  margin-left: 0 !important;

  padding: 0 2rem;
  &__title {
    margin-bottom: 0;
    font-size: 1.3rem;
    &-place {
      font-weight: 400;
      line-height: 2rem;
      margin-left: 0.5rem;
      font-size: 1.2rem;
      @include tablet {
        font-size: 1.3rem;
      }
    }
    &-from,
    &-to {
      color: $sub-text;
    }
  }

  &__departure {
    display: flex;

    &-first {
      width: 15%;
    }
    &-second {
      display: flex;
      width: 75%;
    }
    &-name {
      font-size: 0.9rem;
      font-weight: 600;
    }
    &-time {
      font-size: 0.9rem;
    }
  }

  &__arrival {
    display: flex;
    &-first {
      width: 15%;
    }
    &-second {
      position: relative;
      width: 75%;
      font-weight: 600;
      font-size: 0.9rem;
      span {
        font-weight: 400;
      }
    }
    &-time {
      font-size: 0.9rem;
    }
  }

  &__details {
    display: flex;
    color: $sub-text;
    font-size: 0.95rem;
    &-icon {
      width: 15%;
      &--img {
        width: 1.6rem;
        height: 1.2rem;
        margin-top: 1.3rem;
        margin-left: 1rem;
      }
      &--walking {
        margin-top: 0.7rem;
      }
    }
    &-text {
      position: relative;
      width: 85%;
      margin-left: 0.6rem;
      &--top {
        display: flex;
        margin-left: 0.4rem;
      }
    }
  }

  &__color {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    justify-content: center;
    padding: 0.3rem 0.5rem;
    padding-top: 0.4rem;
    color: $primary-text-color;
  }

  &__towards {
    margin-top: 1.3rem;
  }

  &__sub-details {
    margin-top: 1.3rem;
    margin-bottom: 0;
  }

  &__point {
    position: relative;
    width: 0.9rem;
    height: 0.9rem;
    left: -0.5rem;
    top: 2%;
    margin-top: 0.9rem;
    @include tablet {
      left: -0.61rem;
    }
    @include desktop {
      left: -2.28rem;
    }
  }

  &__line {
    position: absolute;

    &--bus {
      top: -29%;
    }
    top: -37%;

    left: -0.73rem;
    bottom: -24%;
    z-index: -1;

    @include tablet {
      left: -0.8rem;
    }

    @include desktop {
      left: -2.5rem;
    }
    &--walking {
      border-left: dotted $secondary-text-color 5px;
      top: -65%;
    }

    &--second {
      position: absolute;
      left: -2.22rem;
      bottom: -4rem;
      top: -50%;
      z-index: -3;
      &--walking {
        border-left: dotted $secondary-text-color 5px;
        top: -40%;
        bottom: -48%;
        z-index: -4;
      }
    }
  }
}
