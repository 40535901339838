@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.routes {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: 2px solid black;
  z-index: 4;
  @include tablet {
    border: none;
    border-radius: 4px;
    top: 20%;
    left: 5%;
    max-width: 88%;
    max-height: 65vh;
    padding: 1.5rem;
  }
  @include desktop {
    width: 60%;
    left: 20%;
  }

  &__icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__close {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  &__container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    @include tablet {
    }
  }

  &__title {
    display: flex;
    font-size: 1.25rem;
    margin: 2.5rem 0;
    justify-content: center;
    @include tablet {
      margin: 0;
      font-size: 2rem;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0.5rem 0 2.5rem 0;
  }

  &__message {
    display: flex;
    justify-content: center;
    color: map-get($map: $colors, $key: central);
    font-size: 1.25rem;
  }

  &__image {
    height: 15rem;
  }

  &__card {
    max-height: 70vh;
    overflow-y: auto;
    @include tablet {
      max-height: 45vh;
    }
  }

  &__input {
    border-radius: 3px;
    padding: 0.5rem;
    border-radius: 4px;
    border: 2px solid #e0e0e0;
    letter-spacing: 0.01rem;
    padding: 0.3rem 0.3rem 0.3rem 0.5rem;
    height: 2.8rem;
    font-size: 1rem;
    &--error {
      border: 2px solid red;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__icons {
    display: flex;
    gap: 1.5rem;
    transition: transform 0.3s ease;
  }

  &__edit {
    cursor: pointer;
  }

  &__delete {
    cursor: pointer;
  }

  &__name {
    font-size: 1rem;
    @include tablet {
      font-size: 1.1rem;
    }
  }
}

.ReactModal {
  &__Overlay {
    z-index: 11 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(35, 41, 64, 0.7);
  }

  &__Content {
    z-index: 12 !important;
  }
}
