@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.avatar {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: 2px solid black;
  z-index: 4;
  @include tablet {
    border: none;
    border-radius: 4px;
    top: 20%;
    left: 5%;
    max-width: 88%;
    height: 35rem;
    padding: 1.5rem;
  }
  @include desktop {
    width: 60%;
    left: 20%;
  }

  &__input {
    font-size: 1rem;
    color: map-get($map: $colors, $key: jubilee);
    margin-bottom: 2rem;
    &::file-selector-button {
      margin-right: 1rem;
      padding: 0.35em 1rem;
      border-radius: 1rem;
      border: 0px;
      font-size: 0.9rem;
      background-color: map-get($map: $colors, $key: base);
      color: white;

      &:hover {
        background-color: #4b5563;
      }
    }
  }

  &__error {
    color: map-get($map: $colors, $key: central);
    font-size: 0.9rem;
  }

  &__image {
    max-height: 50vh !important;
  }

  &__crop {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    color: white;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
    background-color: #0ea5e9;
    border: none;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #0284c7;
    }
  }

  &__canvas {
    display: none;
    object-fit: contain !important;
    width: 9.375rem !important;
    height: 9.375rem !important;
  }
  &__icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.ReactModal {
  &__Overlay {
    z-index: 11 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(35, 41, 64, 0.3);
  }

  &__Content {
    z-index: 12 !important;
  }
}
