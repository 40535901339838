@use "./typography" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
::placeholder,
button {
  font-family: "Johnston", "Arial", "Helvetica", "sans-serif";
  margin: 0px;
  padding: 0px;
}

ul {
  list-style: none;
}

p {
  line-height: 1.2rem;
}
