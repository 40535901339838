@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.mapboxgl-control-container {
  display: flex;
}

.back {
  background-color: map-get($colors, northern);

  &-container {
    @include container;
    display: flex;
    align-items: center;
    height: 4rem;
    @include desktop {
      padding: 0 2rem;
    }
  }

  &__icon {
    width: 1.45rem;
    height: 1.45rem;
    align-items: center;
    @include tablet {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
  &__text {
    color: $warning;
    font-size: 1.6rem;
    margin: 0.8rem 1rem 0.4rem;
    align-items: center;
    @include tablet {
      font-size: 2rem;
    }
  }
}
.image {
  width: 100%;
  height: 8rem;
  background-size: cover;
}

.restaurants-marker {
  background-image: url("../../assets/icons/restaurant.png");
  width: 2rem;
  height: 2rem;
  background-size: cover;
}

.toilets-marker {
  background-image: url("../../assets/icons/toilet.svg");
  width: 2.5rem;
  height: 2rem;
  background-size: cover;
}

.map {
  width: 100%;
  height: 30rem;
  @include tablet {
    height: 35rem;
  }
  @include desktop {
    height: 100vh;
  }
  &__container {
    @include desktop {
      display: flex;
      flex-direction: row;
      &--selected {
        margin-left: 5rem;
      }
    }
  }
}

.mapboxgl-ctrl-top-left {
  @include tablet {
    display: flex;
    flex-direction: row;
  }
}
.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder:nth-child(1) {
  .suggestions {
    margin: 3.8rem 0 0 0;
    @include tablet {
      margin: 0;
    }
  }
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder:nth-child(2) {
  @include tablet {
    margin-left: 2rem;
  }
}

.mapboxgl-canvas {
  height: 30rem;
  @include tablet {
    height: 35rem;
  }
  @include desktop {
    height: 100vh;
  }
}

.switch-outer {
  display: flex;
  justify-content: center;
  width: 100vw;
  @include desktop {
    width: auto;
  }
}
.toggle-cont {
  width: 7rem;
  height: 3.1rem;
  border-radius: 5rem;
  margin: 0 2rem;
  top: 0%;
  @include tablet {
    width: 6rem;
  }
}

.toggle-cont .toggle-input {
  display: none;
}

.toggle-cont .toggle-label {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 6px;
  width: 100%;
  height: 100%;
  background: #272727;
  border-radius: 5rem;
  box-sizing: content-box;
  box-shadow: 0px 0px 16px -8px #fefefe;
}

.toggle-cont .toggle-label .cont-label-play {
  position: relative;
  width: 50px;
  aspect-ratio: 1 / 1;
  background: #fa8d14;
  border-radius: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}

.toggle-cont .toggle-input:checked + .toggle-label .cont-label-play {
  background: #ffffff;
  transform: translateX(58px);
  @include tablet {
    transform: translateX(50px);
  }
}

.toggle-cont .toggle-label .label-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 2rem;
  aspect-ratio: 1 / 1;
  background-image: url("../../assets/icons/restaurant.png");
  background-repeat: no-repeat;
  background-size: 3rem;
  background-position: 65% 60%;
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}

.toggle-cont .toggle-input:checked + .toggle-label .label-play {
  width: 2rem;
  background-image: url("../../assets/icons/toilet.svg");
  background-position: 65% 60%;
  background-size: 2.1rem;
  background-repeat: no-repeat;
}

.list__container {
  @include desktop {
    display: flex;
    flex-direction: column;
    width: 30%;
  }
}

.switch {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2rem;
  }

  &__grid {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__outer {
    display: flex;
    justify-content: center;
    width: 100vw;
    @include desktop {
      width: auto;
    }
  }
}

.grid {
  @include desktop {
    display: flex;
    justify-content: flex-start;
  }
}

.block {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  @include tablet {
    flex-direction: column;
    justify-content: space-between;
  }
  @include desktop {
    flex-direction: row;
  }
}

#route {
  margin-left: 0;
  width: 80%;
}
.weather {
  margin-left: 0;
  width: 20%;
}
