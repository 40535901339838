@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.sub-hero {
  &__banner {
    margin: 0;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
      height: 2.7rem;
    }
    @include desktop {
      height: 3.9rem;
    }
  }
  &__place {
    margin: 0;
    text-transform: uppercase;
    color: $primary-text-color;
    &--circle {
      color: $secondary-text-color;
    }
    font-size: 1rem;
    @include desktop {
      font-size: 1.5rem;
    }
  }
}
